/**
 * IMPORTS
 */

import {
  call,
  select,
  takeEvery,
} from 'redux-saga/effects';
import {
  captureException,
  captureEvent,
  configureScope,
  addBreadcrumb,
} from '@sentry/browser';
import { getLogEventsIsEnabled } from '../selectors';

/**
 * GLOBALS
 */

const {
  REACT_APP_SENTRY_DSN,
} = process.env;

/**
 * UTILS
 */

function* logEvent(event) {
  if (!REACT_APP_SENTRY_DSN) return;

  const enabled = yield select(getLogEventsIsEnabled);
  if (enabled) {
    yield call(captureEvent, { level: 'debug', ...event });
  }
}

function* logError(error) {
  if (!REACT_APP_SENTRY_DSN) return;

  yield call(captureException, error);
}

/**
 * CORE
 */

function* printError({ type, error }) {
  yield call(console.warn, `Error resulted in "${type}"`);
  yield call(console.error, error);
  yield call(logError, error);
}

function* onPickProject({ projectId }) {
  yield call(configureScope, (scope) => {
    scope.setTag('project', projectId);
    scope.setTag('session', undefined);
  });

  yield call(logEvent, {
    message: `Project "${projectId}" has been picked`,
    extra: { projectId },
  });
}

function* onCreateSessionSuccess({ session }) {
  yield call(configureScope, (scope) => {
    scope.setTag('session', session.id);
  });
}

function* onCompleteStep({ step }) {
  yield call(addBreadcrumb, {
    category: 'nav',
    message: `User has completed "${step}"`,
    level: 'debug',
    data: {
      type: 'complete',
      step,
    },
  });
}

function* onCancelStep({ step }) {
  yield call(addBreadcrumb, {
    category: 'nav',
    message: `User has cancelled "${step}"`,
    level: 'debug',
    data: {
      type: 'cancel',
      step,
    },
  });
}

function* onChangeStepRequest({ from, to, initiator }) {
  yield call(addBreadcrumb, {
    category: 'nav',
    message: `Change from "${from}" to "${to}" requested`,
    level: 'debug',
    data: {
      type: 'change-request',
      from,
      to,
      initiator,
    },
  });
}

function* onChangeStepSuccess({ from, to }) {
  yield call(configureScope, (scope) => {
    scope.setTag('previous-step', from);
    scope.setTag('step', to);
  });

  yield call(logEvent, {
    message: `Change from "${from}" to "${to}" performed`,
    extra: {
      type: 'change-success',
      from,
      to,
    },
  });
}

function* onAddSessionData({ data }) {
  yield call(logEvent, {
    message: 'Data added to session',
    extra: data,
  });
}

export default [
  takeEvery(({ error }) => !!error, printError),
  ...!REACT_APP_SENTRY_DSN
    ? []
    : [
      takeEvery('PICK_PROJECT', onPickProject),
      takeEvery('CREATE_SESSION_SUCCESS', onCreateSessionSuccess),
      takeEvery('COMPLETE_STEP', onCompleteStep),
      takeEvery('CANCEL_STEP', onCancelStep),
      takeEvery('CHANGE_STEP_REQUEST', onChangeStepRequest),
      takeEvery('CHANGE_STEP_SUCCESS', onChangeStepSuccess),
      takeEvery('ADD_SESSION_DATA', onAddSessionData),
    ],
];
