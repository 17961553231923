/**
 * IMPORTS
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import { Translate } from 'react-i18nify';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import ReactInterval from 'react-interval-timer';
import {
  Container,
  Button,
  Form as ReactForm,
} from 'reactstrap';

import SwitchInput from '../inputs/SwitchInput';
import {
  completeStep,
  cancelStep,
} from '../../actions/nav-actions';
import { addSessionData } from '../../actions/session-actions';
import { getProjectForm } from '../../actions/project-actions';
import {
  getCurrentFormBooleanFields,
  getFormInitialValues,
  getCurrentProjectId,
} from '../../selectors';

/**
 * CORE
 */

const TICKS_BEFORE_CANCEL = 60;

class Checkboxes extends Component {
  static stepId = 'checkboxes';

  static propTypes = {
    disabled: PropTypes.bool,
    fields: PropTypes.array,
    projectId: PropTypes.string.isRequired,
    addSessionData: PropTypes.func.isRequired,
    completeStep: PropTypes.func.isRequired,
    cancelStep: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    getProjectForm: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    session: PropTypes.object.isRequired,
  };

  static defaultProps = {
    disabled: false,
    fields: [],
  };

  constructor(props) {
    super(props);

    this.state = {
      ticksBeforeCancel: TICKS_BEFORE_CANCEL,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onCancelTick = this.onCancelTick.bind(this);
  }

  componentWillMount() {
    const { projectId } = this.props;
    this.props.getProjectForm(projectId);
  }

  onSubmit(data) {
    this.props.addSessionData(data);
    this.props.completeStep(Checkboxes.stepId);
  }

  onCancel(event) {
    if (event) {
      event.preventDefault();
    }

    this.props.cancelStep(Checkboxes.stepId);
  }

  onCancelTick() {
    const { ticksBeforeCancel } = this.state;
    const next = ticksBeforeCancel - 1;
    this.setState({ ticksBeforeCancel: next });
    if (next <= 0) {
      this.onCancel();
    }
  }

  render() {
    const { ticksBeforeCancel } = this.state;
    const {
      handleSubmit,
      fields,
      disabled,
    } = this.props;

    return (
      <Container>
        <ReactInterval
          enabled={ticksBeforeCancel > 0 && !disabled}
          callback={this.onCancelTick}
        />
        <ReactForm
          onSubmit={handleSubmit(this.onSubmit)}
          noValidate
        >
          {fields.map(({ name, ...rest }) => {
            const props = { name, key: name, i18nPath: 'Form', ...rest };
            return <SwitchInput {...props} />;
          })}
          <Translate
            value="Form.conditions"
            tag="h4"
          />
          <div>
            <Button
              className="app-button mr-2"
              color="secondary"
              size="lg"
              outline
              onClick={this.onCancel}
            >
              <FA icon={faTimes} />
              &nbsp;
              <Translate value="Form.cancel" />
              {ticksBeforeCancel <= 10 && (
                <span>
                  &nbsp;
                  (
                  {ticksBeforeCancel}
                  )
                </span>
              )}
            </Button>
            <Button
              className="app-button"
              color="primary"
              size="lg"
            >
              <FA icon={faCheck} />
              &nbsp;
              <Translate value="Form.submit" />
            </Button>
          </div>
        </ReactForm>
      </Container>
    );
  }
}

export default compose(
  connect(
    state => ({
      projectId: getCurrentProjectId(state),
      fields: getCurrentFormBooleanFields(state),
      initialValues: getFormInitialValues(state),
    }),
    {
      completeStep,
      cancelStep,
      addSessionData,
      getProjectForm,
    },
  ),
  reduxForm({
    form: 'data-collect-checkboxes',
  }),
)(Checkboxes);
